import oucy from "@/util/oucyUtil";
import { ltDeliveryAddress } from "@/service/index"

export default {
    name: "Enter",
    data() {
        return {

            pageWidth: oucy.pageWidth,

            rules: {

                addressUsername: [
                    { required: true, message: '请输入收货人', trigger: 'change' }
                ],
                addressPhone: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' }
                ],
                cityCode: [
                    { required: true, message: '请输入城市', trigger: 'blur' }
                ],
                addressDetail: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ]
            },
            ruleForm: {
                addressUsername: '',
                addressPhone: '',
                cityCode: '',
                addressDetail: '',
                addressDefault: false,
            },
            dialogVisible: !true,

            list: [],
            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },
        }
    },
    computed: {},
    mounted() {
        this.getAllLtDeliveryAddress()
    },
    methods: {
        getAllLtDeliveryAddress() {
            ltDeliveryAddress.getAllLtDeliveryAddress({}).then(res => {
                this.list = res || []
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {});
        },
        submitForm() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.id) {
                        this.updateLtDeliveryAddress()
                    } else {
                        this.insertLtDeliveryAddress()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        add() {
            this.ruleForm = {
                addressUsername: '',
                addressPhone: '',
                cityCode: '',
                addressDetail: '',
                addressDefault: false,
            }
            // this.dialogVisible = true
            this.$refs.addAddDialog.openDialogVisible()

        },
        edit(v) {
            this.ruleForm = Object.assign({}, v)
            this.ruleForm.cityCode = v.chinaDto && v.chinaDto.idPath ? v.chinaDto.idPath : []
            // this.dialogVisible = true
            this.$refs.addAddDialog.openDialogVisible()
        },
        delMessageBox(fu, v, text, i) {
            this.$confirm(text || '此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this[fu](v, i)
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch((err) => {
                console.log(err)
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        insertLtDeliveryAddress() {
            this.ruleForm.chinaId = this.$oucy.getArrayLast(this.ruleForm.cityCode)
            ltDeliveryAddress.insertLtDeliveryAddress(this.ruleForm).then(res => {
                this.getAllLtDeliveryAddress()
                this.dialogVisible = !true
            })
        },
        updateLtDeliveryAddress() {
            this.ruleForm.chinaId = this.$oucy.getArrayLast(this.ruleForm.cityCode)
            ltDeliveryAddress.updateLtDeliveryAddress(this.ruleForm).then(res => {
                this.getAllLtDeliveryAddress()
                this.dialogVisible = !true

            })
        },
        deleteLtDeliveryAddress(v) {
            ltDeliveryAddress.deleteLtDeliveryAddress(v).then(res => {
                this.getAllLtDeliveryAddress()

            })
        },
        submit() {},
        alert(text, title) {
            this.$alert(text, title || '输入内容不正确', {
                confirmButtonText: '确定',
            });
        },
        callback(){
            this.getAllLtDeliveryAddress()
        },
    }
}