<template>
    <div class="text-left">
        <div style="background-color: #ffffff;">
            <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}">
                <div class="titleBar_left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                    <div class="titleBar_left_txt">
                        企业管理中心
                    </div>
                </div>
                <div class="titleBar_right" @click="$oucy.replace('/')">
                    返回首页
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <!-- <el-breadcrumb-item><a href="/">企业管理中心</a></el-breadcrumb-item> -->
                <el-breadcrumb-item>地址管理</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="container">
            <div class="pageCenter" :style="{width: pageWidth+'px'}">
                <el-container>
                    <div style="padding: 20px 0 50px;width: 100%">
                        <!-- <h3>地址管理</h3> -->
                        <!-- 有货 -->
                        <div class="df-jc-s-b"><span>收货地址</span>
                            <el-button type="primary" @click="add()">新建</el-button>
                        </div>
                        <template>
                            <el-table :data="list" style="width: 100%">
                                <el-table-column prop="addressUsername" label="收货人" width="180">
                                </el-table-column>
                                <el-table-column prop="addressPhone" label="联系电话" width="180">
                                </el-table-column>
                                <el-table-column prop="address" label="详细收货地址">
                                    <template slot-scope="scope">
                                        {{scope.row.chinaDto && scope.row.chinaDto.namePath}}/{{scope.row.addressDetail}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="address" label="操作">
                                    <template slot-scope="scope">
                                        <el-button size="mini" @click="edit(scope.row)">修改</el-button>
                                        <el-button size="mini" @click="delMessageBox('deleteLtDeliveryAddress',scope.row)" type="danger" plain>删除</el-button>
                                        <!-- <el-button size="mini">下架</el-button> -->
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-container>
            </div>
        </div>
        <!-- 添加新地址 | 修改地址 -->
        <add-add-dialog ref="addAddDialog" :ruleForm="ruleForm" @callback="callback"></add-add-dialog>
    </div>
</template>
<script>
import Index from './Index.js'
export default Index
</script>
<style lang="less" scoped>
@import "./Index.less";
</style>